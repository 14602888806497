import React from 'react'
import styled from '@emotion/styled'
import { IoMdPlanet } from "react-icons/io";
import { FaRocket, FaStar } from "react-icons/fa";

const Info = () => {
    return (
        <InfoWrapper>
            <InfoContent>
                <span><IoMdPlanet /></span>
                <h1>Open Source Galaxy</h1>
                <p>Explore the Open Source Galaxy and help other earthlings by contributing to open source.</p>
            </InfoContent>
            <InfoKey>
                <h4>Site Key:</h4>
                <ul>
                    <li><FaStar /> Add to Favorites</li>
                    <li><FaRocket /> Just Launched</li>
                </ul>
            </InfoKey>
        </InfoWrapper>
    )
}

export default Info

// Component Styles 
const InfoWrapper = styled.section`
    align-self: flex-start;
    position: sticky;
    margin-top: var(--md);
    top: var(--md);
    z-index: 1;
    min-height: 450px;
    height: calc(100vh - calc((var(--md) * 2)));
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    background-color: var(--green);
    background-image: var(--gradient);
    border-radius: var(--xxs);
    text-align: center;
    padding: var(--sm);
    @media (max-width: 900px) {
        position: relative;
        top: 0;
        height: 100%;
        min-height: auto;
    }
`

const InfoContent = styled.div`
    span svg {
        font-size: calc((var(--xl) * 2));
        line-height: .5;
        margin-bottom: var(--xxs);
    }
    h1 {
        font-size: var(--lg);
        line-height: 1.25;
        margin-bottom: var(--sm);
    }
    p {
        margin-bottom: var(--xl);
    }
`

const InfoKey = styled.aside`
    h4 {
        font-size: var(--xs);
        margin-bottom: var(--xs);
        opacity: .5;
    }
    li {
        font-size: var(--xs);
        font-weight: 700;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: var(--xs);
        background: var(--black);
        padding: var(--xxs) var(--xs);
        border-radius: var(--xs);
        color: var(--sky);
        svg {
            margin-right: var(--xxs);
        }
    }

`