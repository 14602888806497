import React from "react"
import PropTypes from "prop-types"
import styled from '@emotion/styled'
import "../styles/base.css"
import Helmet from "react-helmet"
import Info from "./info"
import Footer from "./footer"

const Layout = ({ children }) => {
  return (
    <>
      <Helmet>
        <link href="https://fonts.googleapis.com/css?family=Muli:400,400i,700,700i&display=swap" rel="stylesheet" />
      </Helmet>
      <LayoutWrapper>
        <Info />
        <main>
          {children}
        </main>
        <Footer />
      </LayoutWrapper>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

// Component Styles
const LayoutWrapper = styled.div`
    display: grid;
    grid-template-columns: 300px 1fr;
    grid-gap: var(--md);
    max-width: 1300px;
    width: calc(100% - var(--xl));
    margin: 0 auto var(--md);
    .main {
        align-self: flex-start;
    }
    @media (max-width: 900px) {
        grid-template-columns: 1fr;
    }
`