import React from 'react'
import styled from '@emotion/styled'

const Footer = () => {
    return (
        <FooterWrapper>
            <p>All Rights Reserved &copy; {new Date().getFullYear()}</p>
        </FooterWrapper >
    )
}

export default Footer

// Component Styles
const FooterWrapper = styled.footer`
    grid-column: 2;
    border-top: 2px solid var(--gray);
    padding-top: var(--md);
    font-size: var(--xs);
    color: var(--gray);
    text-align: center;
    @media (max-width: 900px) {
        grid-column: 1;
    }
`